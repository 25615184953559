import ProductCard from '../../components/product-card';
import logo from '../../img/logo.png'
import black from '../../img/Black.webp'
import mocha from '../../img/Mocha.webp'
import cognac from '../../img/Cogcac.webp'
import maroon from '../../img/Maroon.webp'
import blouse from '../../img/blouse.webp'
import tunik from '../../img/tunik.webp'
import aurora from '../../img/aurora.webp'
import diana from '../../img/diana.webp'
import crystal from '../../img/crystal.webp'
import halley from '../../img/halley.webp'
import victoria from '../../img/victoria.webp'

function LandingPage() {
  return (
    <div className='mt-8'>
      
      {/* Header */}
      <div className='flex justify-center'>
        <img 
          src={logo} 
          alt='Logo'
          className='object-cover h-10'
        />
      </div>

      {/* Moto */}
      <div className='mx-8 mt-2 text-center'>
        <h1 className='font-sans text-xl text-gray-600'>Premium Wear & Outer Specialist</h1>
      </div>

      {/* CTA Store*/}
      <div className='w-full flex justify-center mt-10'>
        <div className='bg-farose-sign w-2/3 animate-bounce'>
          <a href="https://shopee.co.id/faroseofficial" target="_blank" rel="noreferrer">
            <h1 className='pt-3 px-3 text-center text-gray-100 uppercase font-bold'>Order Via Shopee </h1>
            <h1 className='pb-3 text-center text-gray-100 uppercase font-bold'>( Get Your Discount! )</h1>
          </a>
        </div> 
      </div>

      {/* CTA Instagram */}
      <div className='w-full flex justify-center mt-3'>
        <div className='border-farose-sign border-2 w-2/3'>
          <a href="https://www.instagram.com/faroseofficial" target="_blank" rel="noreferrer">
            <h1 className='py-3 px-3 text-center text-farose-sign uppercase'>Visit Instagram Profile</h1>
          </a>
        </div> 
      </div>

      {/* WA */}
      <div className='w-full flex justify-center mt-3'>
        <div className='border-farose-sign border-2 w-2/3'>
          <a href="http://wa.link/72uxrs" target="_blank" rel="noreferrer">
            <h1 className='py-3 px-3 text-center text-farose-sign uppercase'>WA - Admin</h1>
          </a>
        </div> 
      </div>
      
      {/* Product Cards */}
      <div className='mt-3 flex justify-center flex-wrap'>
        <ProductCard 
          image={tunik}
          targetUrl="https://shopee.co.id/Farose-Constina-Outer-Tunik-i.515239022.19816185200"
          productName="Constina Outer Tunik"
          productPrice="IDR 269.000"
        />
        <ProductCard 
          image={blouse}
          targetUrl="https://shopee.co.id/Farose-Constina-Blouse-i.515239022.18516185335"
          productName="Constina Blouse"
          productPrice="IDR 259.000"
        />
        <ProductCard 
          image={diana}
          targetUrl="https://shopee.co.id/Farose-Outer-Abaya-Diana-i.515239022.18216525318"
          productName="Outer Abaya Diana"
          productPrice="IDR 349.000"
        />
        <ProductCard 
          image={crystal}
          targetUrl="https://shopee.co.id/Farose-Abaya-Crystal-i.515239022.17477363273"
          productName="Nameera Outer Abaya"
          productPrice="IDR 389.000"
        />
        <ProductCard 
          image={aurora}
          targetUrl="https://shopee.co.id/Farose-Outer-Abaya-Aurora-i.515239022.16931145429"
          productName="Outer Abaya Aurora"
          productPrice="IDR 349.000"
        />
        <ProductCard 
          image={halley}
          targetUrl="https://shopee.co.id/Farose-Outer-Abaya-Halley-i.515239022.15666386199"
          productName="Outer Abaya Halley"
          productPrice="IDR 349.000"
        />
        <ProductCard 
          image={victoria}
          targetUrl="https://shopee.co.id/Farose-Outer-Abaya-Victoria-i.515239022.17331154695"
          productName="Outer Abaya Victoria"
          productPrice="IDR 349.000"
        />
        <ProductCard 
          image={black}
          targetUrl="https://shopee.co.id/Farose-Outer-Abaya-Black-i.515239022.16352930387"
          productName="Outer Pearl Black"
          productPrice="IDR 560.000"
        />
        <ProductCard
          image={mocha}
          targetUrl="https://shopee.co.id/Farose-Outer-Abaya-Mocha-i.515239022.10193629281"
          productName="Outer Pearl Mocha"
          productPrice="IDR 560.000"
        />
        <ProductCard
          image={cognac}
          targetUrl="https://shopee.co.id/Farose-Outer-Abaya-Cognac-i.515239022.17952999043"
          productName="Outer Pearl Cognac"
          productPrice="IDR 560.000"
        />
        <ProductCard
          image={maroon}
          targetUrl="https://shopee.co.id/Farose-Outer-Abaya-Maroon-i.515239022.16153014122"
          productName="Outer Abaya Maroon"
          productPrice="IDR 560.000"
        />
      </div>
    </div>
  );
}

export default LandingPage;
