function ProductCard(props){
  return(
    <div className="mr-2 ml-2 mt-5 border-farose-sign">
      <a href={props.targetUrl} target="_blank" rel="noreferrer">
        <div className="bg-white shadow-lg">
          <div className="bg-farose-sign h-44 w-44">
            <img
              src={props.image}
              alt='Product'
              className='object-cover h-44 w-44'
            />
          </div>
          <div className="flex justify-between items-start">
            <div className="pt-2 pl-2 pb-2 flex-grow text-center">
              <h1 className="text-xs uppercase">{props.productName}</h1>
              <div className="text-xs">{props.productPrice}</div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default ProductCard;