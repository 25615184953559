import { Routes, Route } from "react-router-dom";

import LandingPage from "./pages/landing-page";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="landing-page" element={<LandingPage />} />
      </Routes>
    </div>
  );
}

export default App;
